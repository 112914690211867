<template>
  <div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <h1>404 - Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  setup() {
    return {};
  },
};
</script>

<style scoped>
h1 {
  color: red;
}
p {
  font-size: 20px;
}
</style>