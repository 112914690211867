<script>
export default {
  name: "FinancialInformation",
  setup() {
    return {};
  },
};
</script>

<template>
  <div></div>
</template>

<style scoped>

</style>