<script>
export default {
  name: "ContactUs",
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="container">
    <div class="text-box">
      <div class="top"></div>
      <div class="text">
        <h2>您可以在右侧展示得平台找到我们</h2>
        <h2>和我们取得联系</h2>
      </div>
      <div class="bot"></div>
    </div>
    <div class="cards">
      <div class="shell">
        <div class="content">
          <div class="item"></div>
          <div class="item"></div>
          <div class="item"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-size: cover;
  font-family: Arial, serif;
}
.container {
  border-radius: 30px;
  margin: 0 auto;
  width: calc(100vw - 100px);
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.text-box {
  vertical-align: middle;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  color: #1F3979;
}
.top {
  border-bottom: #1F3979 solid 2px;
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bot {
  border-top: #1F3979 solid 2px;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.shell {
  position: relative;
  width: 70vw;
  height: 50vw;
  margin: 0;
  color: white;
  perspective: 1000px;
  transform-origin: center;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform-style: preserve-3d;
  transform: translateZ(-30vw) rotateY(0);
  animation: carousel 9s infinite cubic-bezier(0.77, 0, 0.175, 1) forwards;
}
.item {
  position: absolute;
  width: 450px;
  height: 300px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-size: cover;
  -webkit-box-reflect: below 25px -webkit-linear-gradient(transparent 50px, rgba(255, 255, 255, 0.3));
}
.item:nth-child(1) {
  background-image: url("../assets/ig-card.png");
  transform: rotateY(0) translateZ(35vw);
}
.item:nth-child(2) {
  background-image: url("../assets/wehchat-card.png");
  transform: rotateY(120deg) translateZ(35vw);
}
.item:nth-child(3) {
  background-image: url("../assets/xhs-card.png");
  transform: rotateY(240deg) translateZ(35vw);
}
@keyframes carousel {
  0%, 17.5% {
    transform: translateZ(-35vw) rotateY(0);
  }
  27.5%, 45% {
    transform: translateZ(-35vw) rotateY(-120deg);
  }
  55%, 72.5% {
    transform: translateZ(-35vw) rotateY(-240deg);
  }
  82.5%, 100% {
    transform: translateZ(-35vw) rotateY(-360deg);
  }
}
</style>